<template>
  <button
    :class="'appButton appButton-' + color + (mini ? ' appButton-mini' : '')"
    @click="$emit('click')"
  >
    <span v-if="!$store.state.stripeButtonLoading">
      {{ name }}
    </span>
    <span v-else>
      <svg
        id="L9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <path
          fill="#fff"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </span>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
    mini: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.appButton {
  box-sizing: border-box;
  padding: 0px 30px;
  // height: 100px;
  // min-width: 360px;
  outline: none;
  border: none;
  border-radius: 74px;
  background-color: #e5e5e5;
  color: white;
  cursor: pointer;
  font: {
    size: 18px;
  }

  &-mini {
    // margin-top: 60px;
    padding: 0px 60px;
    height: 60px !important;
    min-width: auto !important;
  }

  &-red {
    // background-color: #ff4f79;
    background: linear-gradient(90deg, #bc4e9c 0%, #f80759 100%);
    box-shadow: 0px 4px 10px rgba(255, 79, 121, 0.5);
  }

  &-blue {
    background-color: #36d5f2;
    box-shadow: 0px 4px 10px rgba(54, 213, 242, 0.5);
  }
}

// @media screen and (max-width: 450px) {
//   .appButton {
//     padding: 0px 10px;
//     min-width: calc(100% - 20px);
//     height: 60px;
//     box-sizing: border-box;
//     margin: 0 auto;
//     // margin-top: 60px;
//     margin-top: 30px;
//     bottom: 0;
//     width: calc(100vw - 120px);
//     text-decoration: none;
//   }
// }
svg {
  width: 33px;
  height: 33px;
}

button {
  border: none;
  color: white;
  padding: 20px 40px;
  height: 60px;
  min-width: 200px;

  // text-transform: uppercase;
  //   border-radius: 40px;
  // border-radius: 140px 290px 120px 190px;
  border-radius: 100px;
  font-size: 20px;
  text-shadow: 1px 0 0 rgba(0, 0, 0, 0.1);
  // -webkit-box-shadow: inset 0px -3px 1px 0px rgba(255, 255, 255, 0.2) !important;
  // box-shadow: inset 0px -3px 1px 0px rgba(255, 255, 255, 0.2) !important;
  position: relative;
  cursor: pointer;
  // -webkit-transition: ease all 150ms;
  // -o-transition: ease all 150ms;
  // transition: ease all 150ms;
}

button:after {
  content: "";
  display: block;
  height: 0.3rem;
  width: 0.6rem;
  position: absolute;
  left: 5%;
  top: 12px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0.9;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.23);
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.23);
  background-image: -o-linear-gradient(
    left,
    hsla(0, 0%, 100%, 0),
    #fff 20%,
    #fff 80%,
    hsla(0, 0%, 100%, 0)
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(hsla(0, 0%, 100%, 0)),
    color-stop(20%, #fff),
    color-stop(80%, #fff),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(
    -270deg,
    hsla(0, 0%, 100%, 0),
    #fff 20%,
    #fff 80%,
    hsla(0, 0%, 100%, 0)
  );
}

button:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 100%;
  border-radius: 40px;
  // border-radius: 140px 290px 120px 190px;
  border-radius: 100px;
  width: 100%;
  display: block;
}

.blue {
  background: -o-linear-gradient(bottom, #00d8ff, #1ce3ff);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#00d8ff),
    to(#1ce3ff)
  );
  background: linear-gradient(0deg, #00d8ff, #1ce3ff);
  background: rgb(0, 205, 242);
  background: -o-linear-gradient(
    bottom,
    rgba(0, 205, 242, 1) 0%,
    rgba(28, 227, 255, 1) 50%,
    rgba(0, 205, 242, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 205, 242, 1)),
    color-stop(50%, rgba(28, 227, 255, 1)),
    to(rgba(0, 205, 242, 1))
  );
  background: linear-gradient(
    0deg,
    rgba(0, 205, 242, 1) 0%,
    rgba(28, 227, 255, 1) 50%,
    rgba(0, 205, 242, 1) 100%
  );
}

.blue:before {
  -webkit-box-shadow: 0 3px 0 -1px #149be3, 0 4px 0 -1px #02bcfa;
  box-shadow: 0 7px 13px -3px rgba(45, 35, 66, 0.3), 0 3px 0 -1px #149be3,
    0 4px 0 -1px #02bcfa;
}

.red {
  background: -o-linear-gradient(bottom, #fe5656, #eb6c6c);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#fe5656),
    to(#eb6c6c)
  );
  background: linear-gradient(0deg, #fe5656, #eb6c6c);
  background: rgb(249, 76, 76);
  background: -o-linear-gradient(
    bottom,
    rgba(249, 76, 76, 1) 0%,
    rgba(235, 132, 108, 1) 50%,
    rgba(249, 76, 76, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(249, 76, 76, 1)),
    color-stop(50%, rgba(235, 132, 108, 1)),
    to(rgba(249, 76, 76, 1))
  );
  background: linear-gradient(
    0deg,
    rgba(249, 76, 76, 1) 0%,
    rgba(235, 132, 108, 1) 50%,
    rgba(249, 76, 76, 1) 100%
  );
  border-radius: 100px;
  // border-radius: 60px 60px 120px 140px;
  //   transform: skewY(-1deg);
}

.red:before {
  -webkit-box-shadow: 0 3px 0 -1px #d33230, 0 4px 0 -1px #f44b45;
  box-shadow: 0 7px 13px -3px rgba(45, 35, 66, 0.3), 0 3px 0 -1px #d33230,
    0 4px 0 -1px #f44b45;
  // border-radius: 60px 60px 120px 140px;
  border-radius: 100px;
}

.yellow {
  background: -o-linear-gradient(bottom, #ffcf00, #f6e765);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#ffcf00),
    to(#f6e765)
  );
  background: linear-gradient(0deg, #ffcf00, #f6e765);
  background: rgb(255, 207, 0);
  background: -o-linear-gradient(
    bottom,
    rgba(255, 207, 0, 1) 0%,
    rgba(246, 231, 101, 1) 50%,
    rgba(255, 207, 0, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(255, 207, 0, 1)),
    color-stop(50%, rgba(246, 231, 101, 1)),
    to(rgba(255, 207, 0, 1))
  );
  background: linear-gradient(
    0deg,
    rgba(255, 207, 0, 1) 0%,
    rgba(246, 231, 101, 1) 50%,
    rgba(255, 207, 0, 1) 100%
  );
}

.yellow:before {
  -webkit-box-shadow: 0 3px 0 -1px #f59316, 0 4px 0 -1px #fbc300;
  box-shadow: 0 7px 13px -3px rgba(45, 35, 66, 0.3), 0 3px 0 -1px #f59316,
    0 4px 0 -1px #fbc300;
}

.gray {
  background: -o-linear-gradient(bottom, #e7e7e7, #efefef);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#e7e7e7),
    to(#efefef)
  );
  background: linear-gradient(0deg, #e7e7e7, #efefef);
  background: rgb(231, 231, 231);
  background: -o-linear-gradient(
    bottom,
    rgba(231, 231, 231, 1) 0%,
    rgba(239, 239, 239, 1) 50%,
    rgba(231, 231, 231, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(231, 231, 231, 1)),
    color-stop(50%, rgba(239, 239, 239, 1)),
    to(rgba(231, 231, 231, 1))
  );
  background: linear-gradient(
    0deg,
    rgba(231, 231, 231, 1) 0%,
    rgba(239, 239, 239, 1) 50%,
    rgba(231, 231, 231, 1) 100%
  );
}

.gray:before {
  -webkit-box-shadow: 0 3px 0 -1px #d3d3d3, 0 4px 0 -1px #d9d9d9;
  box-shadow: 0 7px 13px -3px rgba(45, 35, 66, 0.3), 0 3px 0 -1px #d3d3d3,
    0 4px 0 -1px #d9d9d9;
}

.redish {
  background: -o-linear-gradient(bottom, #ffcf00, #ff6464);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#ffcf00),
    to(#ff6464)
  );
  background: linear-gradient(0deg, #ffcf00, #ff6464);
}

.redish:before {
  -webkit-box-shadow: 0 3px 0 -1px #f59316, 0 4px 0 -1px #fbc300;
  box-shadow: 0 3px 0 -1px #f59316, 0 4px 0 -1px #fbc300;
}

.blue:hover:before,
.yellow:hover:before,
.gray:hover:before,
.redish:hover:before,
.red:hover:before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  // -webkit-transition: ease all 150ms;
  // -o-transition: ease all 150ms;
  // transition: ease all 150ms;
}
.blue:hover,
.yellow:hover,
.gray:hover,
.redish:hover,
.red:hover {
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  -webkit-box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.2) !important;
  box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.2) !important;
}
</style>
